import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import Block, { BlockProps } from './Block'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import JSONParseOr from 'lib/JSONParseOr'
import List from 'components/list/List'
import ListItem from 'components/list/ListItem'
import RelatedResourceRecordsView from 'components/views/graph/RelatedResourceRecordsView'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './wrappers/useExecuteOperationQuery'
import { ATTRIBUTES_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { ChipRenderer } from 'components/displayTypes/ChipView'
import { CodeRenderer } from 'components/displayTypes/CodeView'
import { CurrencyRenderer } from 'components/displayTypes/CurrencyView'
import { CustomRecord, Relationship, useAttributesListQuery, useInternalFetchRecordQuery, useInternalSummarizeRecordsQuery, useRelationshipsListQuery } from 'generated/schema'
import { DateTimeRenderer } from 'components/displayTypes/DateTimeView'
import { DisplayType } from 'models/Attribute'
import { DurationRenderer } from 'components/displayTypes/DurationView'
import { EmbeddedRenderer } from 'components/displayTypes/EmbeddedView'
import { FileRenderer } from 'components/displayTypes/FileView'
import { GenericRenderer } from 'components/displayTypes/GenericView'
import { LinkRenderer } from 'components/displayTypes/LinkView'
import { MediaRenderer } from 'components/displayTypes/MediaView'
import { NumberRenderer } from 'components/displayTypes/Number'
import { parseAndRenderSync } from 'lib/templater'
import { PhoneNumberRenderer } from 'components/displayTypes/PhoneNumberView'
import { PlainTextRenderer } from 'components/displayTypes/PlainTextView'
import { ReferenceRenderer } from 'components/displayTypes/ReferenceView'
import { styled } from 'styles/stitches'
import { SwitchRenderer } from 'components/displayTypes/SwitchView'
import { useViewDispatch } from 'hooks/useViewContext'

type DetailsBlockProps = BlockProps & {
  asFragment?: boolean,
  resourceId: string,
  recordId: string,
  targetEnvironment?: string,
  prefix?: string,
  suffix?: string
}

const StyledListItem = styled(ListItem, {
  ...mixins.transition('simple'),

  [`${Icon}`]: {
    color: 'dark100'
  },

  '&:hover': {
    [`${Icon}`]: {
      color: 'dark300'
    }
  }
})

type RelatedListItemProps = {
  record: CustomRecord,
  relationship: Relationship,
  onClick: () => void
}

const RelatedListItem = ({ relationship, record, onClick }: RelatedListItemProps) => {
  const {
    data: { internalSummarizeRecords: { count } = { count: 0 } } = {}
  } = useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId: relationship.target.id,
        filter: {
          [relationship.targetAttribute.identifier]: {
            eq: record.data[relationship.sourceAttribute.identifier]?.en_US
          }
        }
      }
    }
  })

  return (
    <StyledListItem
      height={48}
      alignItems="center"
      onClick={onClick}
      gap={8}
    >
      <Text fontSize={14} fontWeight="semibold">{relationship.name}</Text>
      <Flex grow={1} />
      <Chip variant="dark" label={count} />
      <Icon name="arrow-right" size={12} style={{ right: -14, position: 'relative' }} />
    </StyledListItem>
  )
}

const RENDERER_MAP: Record<keyof typeof DisplayType, any> = {
  [DisplayType.CHIP]: ChipRenderer,
  [DisplayType.CODE]: CodeRenderer,
  [DisplayType.CURRENCY]: CurrencyRenderer,
  [DisplayType.DATE_TIME]: DateTimeRenderer,
  [DisplayType.DURATION]: DurationRenderer,
  [DisplayType.EMBEDDED]: EmbeddedRenderer,
  [DisplayType.LINK]: LinkRenderer,
  [DisplayType.PHONE_NUMBER]: PhoneNumberRenderer,
  [DisplayType.PLAIN_TEXT]: PlainTextRenderer,
  [DisplayType.REFERENCE]: ReferenceRenderer,
  [DisplayType.RENDERED_HTML]: CodeRenderer,
  [DisplayType.RENDERED_MARKDOWN]: CodeRenderer,
  [DisplayType.SENSITIVE_TEXT]: GenericRenderer,
  [DisplayType.SWITCH]: SwitchRenderer,
  [DisplayType.DOCUMENT]: FileRenderer,
  [DisplayType.FILE]: FileRenderer,
  [DisplayType.IMAGE]: MediaRenderer,
  [DisplayType.AUDIO]: MediaRenderer,
  [DisplayType.VIDEO]: MediaRenderer,
  [DisplayType.MEDIA]: MediaRenderer,
  [DisplayType.NUMERIC]: NumberRenderer
}

const getRenderer = (displayType: DisplayType) => (RENDERER_MAP[displayType] || PlainTextRenderer)

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str, ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

function DetailsBlock(
  { asFragment, blockRef, resourceId, operationId, parameters, rows = [], heading, recordId, prefix = 'data', suffix = 'en_US', switcher, ...other }: DetailsBlockProps
) {
  const targetEnvironment = switcher?.data.environment?.id
  const {
    data: { relationshipsList = [] } = {},
    loading: relationshipsLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const { openView } = useViewDispatch()

  const {
    data: { internalFetchRecord: record } = {},
    loading,
    error
  } = useInternalFetchRecordQuery({
    variables: {
      input: {
        targetEnvironment,
        resourceId,
        arguments: {
          id: recordId
        }
      }
    },
    skip: !targetEnvironment || !resourceId || !recordId
  })

  const {
    data: { attributesList: attributes = [] } = {},
    loading: attributesLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: ATTRIBUTES_LIST_LIMIT
    },
    skip: !resourceId
  })

  const openRelatedView = (relationship: Relationship) => openView({
    title: relationship.target?.name || 'Resource',
    component: RelatedResourceRecordsView,
    style: RelatedResourceRecordsView.defaultStyle,
    params: {
      resource: relationship.target,
      switcher,
      relationship,
      record: record!
    }
  })

  const { blockPropertiesState } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)
  const {
    data: { executeQueryOperation: operationRecord } = {},
    loading: executeOperationLoading,
    error: executeOperationError
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: parameters,
    targetEnvironment
  })

  const details = (
    <Flex direction="column" gap={24}>
      {heading && <Text fontWeight="bold">{heading}</Text>}
      <SectionLoader
        data={(record || operationRecord) && attributes}
        loading={relationshipsLoading || attributesLoading || loading || executeOperationLoading}
        error={error || executeOperationError}
      >
        {rows.length ? (
          <Flex direction="column" gap={16}>
            {rows.map((row: any) => {
              if (row.is_hidden) return null

              const prefixContent = prefix ? `${prefix}.` : ''
              const suffixContent = suffix ? `.${suffix}` : ''
              const attr = row.attribute && attributes.find((a) => a.id === row.attribute)
              const attributeName = `${prefixContent}${camelCase(attr?.identifier)}${suffixContent}`

              const data = attr
                ? get(record, attributeName)
                : JSONParseOr(safeParseLiquid(row.value, { record: operationRecord }))

              const Renderer = getRenderer(row.display_type)
              const displaySettings = { ...(attr || {}), ...(row.display_type_settings || {}), fontWeight: 'bold' }

              return (
                <Flex direction="column" gap={10} key={attr?.id || row.label}>
                  <Text fontSize={12} color="dark500" textTransform="uppercase">{row.label || attr?.name}</Text>
                  <ErrorBoundary
                    fallback={<GenericRenderer displaySettings={displaySettings} data={data} />}
                    onError={console.error}
                  >
                    <Renderer displaySettings={displaySettings} data={data} />
                  </ErrorBoundary>
                </Flex>
              )
            })}
          </Flex>
        ) : !!attributes.length && (
          <Flex direction="column" gap={16}>
            {attributes.map((attr) => {
              const prefixContent = prefix ? `${prefix}.` : ''
              const suffixContent = suffix ? `.${suffix}` : ''
              const attributeName = `${prefixContent}${camelCase(attr.identifier)}${suffixContent}`
              const data = get(record, attributeName)
              const Renderer = getRenderer(attr.displayType as DisplayType)
              const displaySettings = { ...(attr.displayTypeSettings || {}), fontWeight: 'bold' }

              return (
                <Flex direction="column" gap={10} key={attr.id}>
                  <Text fontSize={12} color="dark500" textTransform="uppercase">{attr.name}</Text>
                  <ErrorBoundary
                    fallback={<GenericRenderer displaySettings={displaySettings} data={data} />}
                  >
                    <Renderer displaySettings={displaySettings} data={data} />
                  </ErrorBoundary>
                </Flex>
              )
            })}
          </Flex>
        )}

        {!!relationshipsList.length && (
          <Flex direction="column" gap={12}>
            <Text fontWeight="bold">Related</Text>
            <List gap={8}>
              {relationshipsList.map((relationship) => (
                <RelatedListItem
                  key={relationship.id}
                  record={record as CustomRecord}
                  relationship={relationship as Relationship}
                  onClick={() => openRelatedView(relationship as Relationship)}
                />
              ))}
            </List>
          </Flex>
        )}
      </SectionLoader>
    </Flex>
  )

  if (asFragment) return details
  return (
    <Block direction="column" masonryItemRef={blockRef} {...other}>
      {details}
    </Block>
  )
}

export default DetailsBlock

export type { DetailsBlockProps }

export { getRenderer, RENDERER_MAP }
