import React from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Tooltip from 'components/tooltip/Tooltip'
import { styled } from 'styles/stitches'
import type { IconProps } from 'components/icons/Icon'

type IconButtonVariant = 'light' | 'dark' | 'darker' | 'negative'

type IconButtonProps = IconProps & React.ComponentProps<typeof StyledIconButton> & {
  description: string,
  hideTooltip?: boolean,
  isVisible?: boolean,
  disabled?: boolean,
  variant: IconButtonVariant
}

const StyledIcon = styled(Icon, {
  ...mixins.transition('fluid'),
  opacity: 0,
  variants: {
    visible: {
      true: {
        opacity: 1
      }
    }
  }
})

const StyledIconButton = styled(Flex, {
  cursor: 'pointer',
  padding: 6,
  ':disabled': {
    cursor: 'not-allowed'
  },

  variants: {
    variant: {
      darker: {
        ':disabled': {
          [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
            color: 'dark300'
          }
        },
        [`& > ${StyledIcon}`]: {
          color: 'dark300'
        },
        [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
          color: 'dark800'
        }
      },
      dark: {
        ':disabled': {
          [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
            color: 'dark100'
          }
        },
        [`& > ${StyledIcon}`]: {
          color: 'dark100'
        },
        [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
          color: 'dark600'
        }
      },
      light: {
        ':disabled': {
          [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
            color: 'light100',
            opacity: 0.7
          }
        },
        [`& > ${StyledIcon}`]: {
          color: 'light100',
          opacity: 0.7
        },
        [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
          opacity: 1
        }
      },
      negative: {
        ':disabled': {
          [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
            color: 'negative100'
          }
        },
        [`& > ${StyledIcon}`]: {
          color: 'negative100'
        },
        [`&:hover > ${StyledIcon}, &:focus > ${StyledIcon}`]: {
          color: 'negative600'
        }
      }
    }
  }
})

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({
    className,
    description,
    hideTooltip = false,
    isVisible = true,
    name,
    size,
    variant = 'dark',
    ...others
  }, ref) => {
    const content = (
      // @ts-ignore
      <StyledIconButton
        aria-label={description}
        as="button"
        variant={variant}
        className={className}
        type="button"
        ref={ref}
        {...others}
      >
        <StyledIcon
          data-icon
          name={name}
          size={size}
          visible={isVisible}
          className={variant}
        />
      </StyledIconButton>
    )

    if (hideTooltip) {
      return content
    }

    return (
      <Tooltip description={description}>
        {content}
      </Tooltip>
    )
  }
)

IconButton.displayName = 'IconButton'

export type {
  IconButtonProps,
  IconButtonVariant
}

export default IconButton
