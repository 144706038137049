import arrayMutators from 'final-form-arrays'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import React, { useContext, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import { Form, FormSpy } from 'react-final-form'
import { number, object, string } from 'yup'
import { useRecoilValue } from 'recoil'
import type { FormApi, FormState } from 'final-form'

import BaseModel from 'models/BaseModel'
import Block from './Block'
import Button from 'components/buttons/Button'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import JSONParseOr from 'lib/JSONParseOr'
import Loader from 'components/loaders/Loader'
import ParameterFields from 'components/resource/ParameterFields'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useRecordPublishing, { PublishingAction } from 'hooks/useRecordPublishing'
import useSubmitHandler from 'hooks/useSubmitHandler'
import {
  useInternalEditRecordMutation,
  InternalAddRecordInput,
  InternalEditRecordInput,
  Operation,
  useInternalAddRecordMutation,
  InternalSearchRecordsDocument,
  InternalSummarizeRecordsDocument,
  ValidationInput,
  Parameter,
  useOperationQuery,
  useExecuteMutationOperationMutation
} from 'generated/schema'
import { createFocusOnErrors } from 'lib/formDecorators/focusOnErrors'
import { FieldIdentifier } from 'models/Field'
import { MatchPattern } from 'components/dashboardEditor/graph/AttributeValidations'
import { useViewDispatch } from 'hooks/useViewContext'
import { ValidationKind } from 'models/Attribute'
import { parseAndRenderSync } from 'lib/templater'
import { PublishButton, SubmitButton } from 'components/views/cms/AddContentView'
import { Views } from 'components/dashboardEditor/constants'
import type { BlockProps } from './Block'

type FormValues = InternalAddRecordInput | InternalEditRecordInput | Record<any, any>;

type FormBlockProps = BlockProps & {
  asFragment?: boolean,
  initialValues?: Record<any, any>,
  operationId: string,
  resourceId?: string,
  targetEnvironment?: string,
  prefix?: string,
  suffix?: string,
  footerEl?: HTMLDivElement | null,
  onChange?: (values: FormState<FormValues>) => void,
  publishedId?: string,
  latestId?: string,
  isPublishingEnabled?: boolean
}

const focusOnErrors = createFocusOnErrors<FormValues>()

const getSchema = (fieldType: string, validations: ValidationInput[]) => {
  let schema: any
  const isString = fieldType === FieldIdentifier.TEXT
  const isNumber = fieldType === FieldIdentifier.NUMBER

  if (isString) schema = string()
  if (isNumber) schema = number().typeError('must be a number')

  validations.map((val) => {
    if (!isEmpty(val.criteria)) return val
    if (schema) {
      switch (val.kind) {
        case ValidationKind.PRESENCE:
          schema = schema.required()
          break

        case ValidationKind.LENGTH: {
          if (!isString) break
          const {
            minimum,
            maximum,
            equal_to: equalTo,
            not_equal_to: notEqualTo
          } = val.settings

          if (minimum) schema = schema.min(minimum)
          if (maximum) schema = schema.max(maximum)
          if (equalTo) schema = schema.length(equalTo)
          if (notEqualTo) {
            schema = schema.test(
              'not-equal',
              `length cannot not be ${notEqualTo}`,
              (value: string) => value?.length !== notEqualTo
            )
          }

          break
        }

        case ValidationKind.MATCHES: {
          if (!isString) break

          const { pattern } = val.settings

          if (typeof pattern === 'object') {
            const { custom } = pattern
            if (custom) schema = schema.matches(pattern)
            break
          }

          if (pattern === MatchPattern.EMAIL) schema = schema.email()
          if (pattern === MatchPattern.URL) schema = schema.url()
          if (pattern === MatchPattern.UUID) schema = schema.uuid()

          break
        }

        case ValidationKind.NOT_MATCHES: {
          if (!isString) break

          const { pattern } = val.settings

          if (typeof pattern === 'object') {
            const { custom } = pattern
            if (custom) {
              schema = schema.test(
                'not-matches',
                `cannot not be ${custom}`,
                (value: string) => (value && custom instanceof RegExp
                  ? !custom.test(value)
                  : false)
              )
            }
            break
          }

          break
        }

        case ValidationKind.INCLUSION: {
          const { values } = val.settings
          if (values?.length) schema = schema.oneOf(values)
          break
        }

        case ValidationKind.EXCLUSION: {
          const { values } = val.settings
          if (values?.length) schema = schema.notOneOf(values)
          break
        }

        default:
          break
      }
    }

    return val
  })

  return schema
}

const validate = (values: FormValues, parameters: Parameter[], resourceId?: string) => {
  const args = {} as any

  parameters?.map(({ identifier, fieldType, validations }) => {
    if (fieldType && validations.length) {
      const schema = getSchema(fieldType, validations as ValidationInput[])

      if (schema) {
        const key = resourceId ? camelCase(identifier) : identifier
        args[key] = schema
      }
    }

    return null
  })

  return BaseModel.validateSchema(values, {
    arguments: object(args)
  })
}

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str, ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

function FormBlock({
  heading,
  initialValues,
  operationId,
  fields,
  resourceId,
  asFragment,
  footerEl,
  blockRef,
  prefix,
  suffix,
  switcher,
  identifier,
  onChange,
  publishedId,
  latestId,
  isPublishingEnabled,
  ...others
}: FormBlockProps) {
  const { blockPropertiesState, selectBlock, openDashboardEditorView } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)
  const { openDashboardEditor } = useContext(DashboardContext)!
  const isPublished = isPublishingEnabled && !!publishedId && publishedId === latestId
  const targetEnvironment = switcher?.data.environment?.id

  const formInitialValues = useMemo(() => {
    let values = {}
    if (typeof initialValues === 'string') {
      values = JSONParseOr(safeParseLiquid(initialValues, blockProperties), {})
    } else if (initialValues) {
      values = initialValues
    }

    return {
      operationId,
      resourceId,
      targetEnvironment,
      arguments: suffix
        ? mapValues(
          values,
          suffix
        )
        : values
    }
  }, [ blockProperties, initialValues, operationId, resourceId, suffix, targetEnvironment ])

  const isUpdating = typeof formInitialValues.arguments === 'object' && 'id' in formInitialValues.arguments

  const { data: { operation } = {}, loading: operationLoading } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const { closeView } = useViewDispatch()

  const createMutationOptions = {
    onCompleted: () => closeView(),
    refetchQueries: [ InternalSearchRecordsDocument, InternalSummarizeRecordsDocument ]
  }

  const [ executeOperation ] = useExecuteMutationOperationMutation({
    onCompleted: () => closeView()
  })

  const [ createResource ] = useInternalAddRecordMutation(createMutationOptions)
  const [ updateResource ] = useInternalEditRecordMutation({ onCompleted: () => closeView() })

  const handleExecuteOperation = useSubmitHandler(executeOperation, {
    successAlert: { message: `${operation?.name || 'Operation'} successful.` }
  })

  const handleCreateResource = useSubmitHandler(createResource, {
    successAlert: operation
      ? { message: `${operation.resource?.name} added.` }
      : undefined
  })

  const handleUpdateResource = useSubmitHandler(updateResource, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'internalEditRecord',
      typename: 'Record',
      override: (values: InternalEditRecordInput) => ({
        ...formInitialValues,
        data: values
      })
    },
    successAlert: operation?.resource
      ? { message: `${operation.resource.name} updated.` }
      : undefined
  })

  const handleSubmit = (values: FormValues, form: FormApi<FormValues>) => {
    const args = (operation?.parameters || [])
      .map((param) => {
        const key = resourceId ? camelCase(param.identifier) : param.identifier
        const paramName = `arguments.${key}`
        const paramValue = (
          param.fieldType === FieldIdentifier.FILE && isUpdating && get(values, `${paramName}.id`)
        ) || get(values, paramName)

        return set({}, key, paramValue)
      })

    if (resourceId) {
      const formValues = {
        resourceId,
        arguments: args.reduce((acc, curr) => ({ ...acc, ...curr })),
        targetEnvironment
      }

      if (isUpdating) {
        return handleUpdateResource(
          formValues as InternalEditRecordInput, form as FormApi<InternalEditRecordInput>
        )
      }

      return handleCreateResource(formValues as InternalAddRecordInput)
    }

    const formValues = {
      operationId,
      arguments: args.reduce((acc, curr) => merge({}, acc, curr), { ...values.arguments }),
      targetEnvironment
    }

    return handleExecuteOperation(formValues)
  }

  const parameters = fields
    ? fields.map((field: any) => {
      if (field.is_hidden) return null
      return operation?.parameters.find((p) => p.id === field.parameter)
    }).filter(Boolean)
    : operation?.parameters

  const shouldShowPublishing = isPublishingEnabled && isUpdating

  const [ handlePublishing ] = useRecordPublishing()

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      id: latestId
    })
  )

  const formBlock = (
    <Form
      decorators={[ focusOnErrors ]}
      keepDirtyOnReinitialize={!!resourceId}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators
      }}
      subscription={{
        submitting: true
      }}
      validate={(values) => validate(values, operation?.parameters as Parameter[], resourceId)}
      render={({ handleSubmit, submitting }) => {
        if (!resourceId && !operationId) {
          return (
            <Flex direction="column" gap={18} alignItems="center" css={{ padding: 24 }}>
              <Text>New Form</Text>
              <Button
                mode="subtle"
                label="Configure"
                size="small"
                variant="outline"
                onClick={() => {
                  selectBlock(others.id)
                  openDashboardEditor()
                  openDashboardEditorView({
                    target: Views.EDIT_BLOCK
                  })
                }}
              />
            </Flex>
          )
        }

        return (
          <>
            <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
              {heading && <Text fontWeight="bold">{heading}</Text>}
              <Loader
                loading={operationLoading}
                data={operation}
              >
                <ParameterFields
                  currentLocale={{
                    name: 'Default',
                    identifier: 'en_US',
                    isDefault: true,
                    allowFallback: false,
                    fallbacks: []
                  }}
                  defaultLocale={{
                    name: 'Default',
                    identifier: 'en_US',
                    isDefault: true,
                    allowFallback: false,
                    fallbacks: []
                  }}
                  isUpdating={isUpdating}
                  operation={operation as Operation}
                  parameters={parameters as Parameter[]}
                  prefix="arguments"
                  resourceId={operation?.resource?.id!}
                  targetEnvironmentId={targetEnvironment}
                />
              </Loader>
              <input style={{ display: 'none' }} type="submit" />
              {onChange && <FormSpy onChange={onChange} />}
            </Flex>
            {footerEl ? (
              <Portal target={footerEl}>
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={Boolean(isPublishingEnabled)}
                />
                {shouldShowPublishing && (
                <PublishButton
                  isPublished={Boolean(isPublished)}
                  submitting={submitting}
                  onClick={onPublishingClick}
                />
                )}
              </Portal>
            ) : (
              <Flex gap={24} direction="row-reverse">
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={Boolean(isPublishingEnabled)}
                />
                {shouldShowPublishing && (
                <PublishButton
                  isPublished={Boolean(isPublished)}
                  submitting={submitting}
                  onClick={onPublishingClick}
                />
                )}
              </Flex>
            )}
          </>
        )
      }}
    />
  )

  if (asFragment) {
    return formBlock
  }

  return (
    <Block direction="column" masonryItemRef={blockRef} {...others}>
      {formBlock}
    </Block>
  )
}

export type { FormBlockProps }

export default React.memo(FormBlock)
