import countryList from 'react-select-country-list'
import dayjs from 'dayjs'
import get from 'lodash/get'
import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import type { ApolloError } from '@apollo/client'
import type { SeriesColumnOptions } from 'highcharts'

import * as mixins from 'styles/mixins'
import AddPaymentMethodView from 'components/views/AddPaymentMethodView'
import BarChart from 'components/charts/BarChart'
import BillingAccount, { TaxRegistrationKind, TAX_REGISTRATION_KIND_OPTIONS, TAX_REGISTRATION_VALUE_PLACEHOLDER_MAP } from 'models/BillingAccount'
import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import Card from 'components/card/Card'
import Chip from 'components/chip/Chip'
import CreditCard from 'components/card/CreditCard'
import Currency from 'components/typography/Currency'
import CurrencyRenderer from 'components/renderers/CurrencyRenderer'
import DataTable from 'components/dataTable/DataTable'
import Divider from 'components/divider/Divider'
import DrawerBlock, { DrawerBlockRenderProps } from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import IconButton from 'components/buttons/IconButton'
import InvoiceNameRenderer from 'components/renderers/InvoiceNameRenderer'
import List from 'components/list/List'
import NumberText from 'components/typography/NumberText'
import rgba from 'lib/rgba'
import SectionLoader from 'components/loaders/SectionLoader'
import SelectInput from 'components/inputs/SelectInput'
import StatusRenderer from 'components/renderers/StatusRenderer'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TitleBlock from 'components/blocks/TitleBlock'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { colorVars } from 'styles/theme'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { SaveWorkspaceBillingAccountInput, useFetchCurrentWorkspaceOrderQuery, useSaveWorkspaceBillingAccountMutation, useWorkspaceOrdersListQuery, useWorkspacePaymentMethodsListQuery, useSetWorkspacePaymentMethodAsDefaultMutation, useDestroyWorkspacePaymentMethodMutation, SetWorkspacePaymentMethodAsDefaultInput, PaymentMethod, WorkspaceBillingAccount, CustomOrder, WorkspacePaymentMethodsListDocument, useFetchWorkspaceBillingAccountQuery, WorkspacePaymentMethodsListQueryVariables, WorkspacePaymentMethodsListQuery } from 'generated/schema'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Brands } from 'models/PaymentMethod'
import type { Column } from 'components/dataTable/types'

const NOT_PENDING_ORDER_TYPES = [ 'PAID', 'CANCELED' ]

const BillingListHeader = styled(Flex, {
  display: 'flex',
  height: 40,
  width: '100%',
  fontWeight: 600,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 48,
  padding: '10px 16px',

  [`& > ${Text}`]: {
    fontSize: 12,
    color: 'dark600',
    textTransform: 'uppercase'
  },

  '& :first-child': {
    flexGrow: 1
  },

  '& > *:not(:first-child)': {
    width: 100,
    textAlign: 'end'
  }
})

const BillingListItem = styled(Flex, {
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),
  ...mixins.transition('simple'),

  display: 'flex',
  height: 40,
  backgroundColor: 'light100',
  borderRadius: 6,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 48,
  padding: '10px 16px',

  [`& > ${Text}`]: {
    fontWeight: 600,
    fontSize: 14
  },

  '& :first-child': {
    flexGrow: 1
  },

  '& > *:not(:first-child)': {
    width: 100,
    textAlign: 'end',
    color: 'dark700'
  }
})

const UsageSummary = ({
  data, loading, error
}: {
  data?: CustomOrder, loading?: boolean, error?: ApolloError
}) => (
  <Flex direction="column" gap={16}>
    <SectionLoader data={data} loading={loading} error={error}>
      <List>
        <BillingListHeader>
          <Text>Item</Text>
          <Text>Quantity</Text>
          <Text>Rate</Text>
          <Text>Subtotal</Text>
        </BillingListHeader>
        <SectionLoader
          data={data?.orderItems}
          loading={loading}
          error={error}
        >
          {data?.orderItems.map((lineItem) => (
            <BillingListItem>
              <Text>{lineItem.item.name}</Text>
              <NumberText>{lineItem.quantity}</NumberText>
              <Currency currenyCode={lineItem.currencyCode} value={lineItem.unitPrice} />
              <Currency currenyCode={lineItem.currencyCode} value={lineItem.subtotal} />
            </BillingListItem>
          ))}
        </SectionLoader>
      </List>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={12} textTransform="uppercase" fontWeight="bold" color="dark700">Total (Billable at month end)</Text>
        <Currency
          currencyCode={data?.currencyCode}
          size="large"
          value={data?.total}
        />
      </Flex>
    </SectionLoader>
  </Flex>
)

type InvoiceSettingsFormValues = SaveWorkspaceBillingAccountInput

const InvoiceSettings = ({
  data, loading, error, onClose
}: {
  data?: WorkspaceBillingAccount,
  loading?: boolean,
  error?: ApolloError,
  onClose: () => void
}) => {
  const options = useMemo(() => countryList().getData(), [])

  const [ saveWorkspaceBillingAccount ] = useSaveWorkspaceBillingAccountMutation({
    onCompleted: onClose
  })

  const handleSave = useSubmitHandler(saveWorkspaceBillingAccount, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'saveWorkspaceBillingAccount',
      typename: 'FetchWorkspaceBillingAccountResponse'
    }
  })

  const initialValues: InvoiceSettingsFormValues = {
    id: data?.id || '',
    name: data?.name || '',
    address: data?.address || {
      city: '',
      line1: '',
      line2: '',
      countryCode: '',
      kind: 'BILLING',
      state: '',
      zip: ''
    },
    taxRegistration: data?.taxRegistration || {
      value: '',
      kind: 'EU_VAT'
    }
  }

  const onSubmit = (values: InvoiceSettingsFormValues) => handleSave(values)

  return (
    <SectionLoader data={data} loading={loading} error={error}>
      <Flex gap={32}>
        <Flex direction="column" gap={16} css={{ flexBasis: '50%' }}>
          <Text color="dark900" fontSize={14} fontWeight="semibold">Enter billing details of the legal entity that must appear on all invoices.</Text>
          <Form
            keepDirtyOnReinitialize
            initialValues={initialValues}
            validate={(values) => BillingAccount.validate(values, [ 'name', 'address', 'taxRegistration' ])}
            subscription={{
              submitting: true,
              pristine: true
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Flex direction="column" gap={16}>
                  <Flex direction="column" gap={16}>
                    <Field
                      autoFocus
                      component={TextInput}
                      size="small"
                      name="name"
                      label="Legal Name"
                      type="text"
                    />
                    <Flex direction="column" gap={8}>
                      <Field component="input" name="address.kind" type="hidden" />
                      <Field
                        component={TextInput}
                        size="small"
                        name="address.line1"
                        label="Billing Address"
                        placeholder="Street Address"
                        type="text"
                      />
                      <Field
                        component={TextInput}
                        size="small"
                        name="address.line2"
                        placeholder="Apt, Suite, Bldg, Gate Code. (optional)"
                        type="text"
                      />
                      <Flex gap={8}>
                        <Field
                          component={TextInput}
                          size="small"
                          name="address.city"
                          placeholder="City"
                          type="text"
                        />
                        <Field
                          component={TextInput}
                          size="small"
                          name="address.state"
                          placeholder="State"
                          type="text"
                        />
                      </Flex>
                      <Flex gap={8}>
                        <Field
                          component={TextInput}
                          size="small"
                          name="address.zip"
                          placeholder="Zip"
                          type="text"
                        />
                        <Field
                          component={SelectInput}
                          size="small"
                          name="address.countryCode"
                          placeholder="County"
                          type="text"
                          options={options}
                        />
                      </Flex>
                    </Flex>
                    <Flex gap={8} alignItems="flex-end">
                      <Box style={{ width: 250 }}>
                        <Field
                          component={SelectInput}
                          size="small"
                          name="taxRegistration.kind"
                          label="Tax Information"
                          options={TAX_REGISTRATION_KIND_OPTIONS}
                          value={TAX_REGISTRATION_KIND_OPTIONS[0].value}
                        />
                      </Box>
                      <FormValuesField fieldNames={[ 'taxRegistration.kind' ]}>
                        {(values) => {
                          const kind = get(values, 'taxRegistration.kind') || null
                          return (
                            <Field
                              component={TextInput}
                              size="small"
                              name="taxRegistration.value"
                              type="text"
                              placeholder={kind
                                ? TAX_REGISTRATION_VALUE_PLACEHOLDER_MAP[
                                  kind as TaxRegistrationKind
                                ]
                                : 'Enter Tax Registration Number'}
                            />
                          )
                        }}
                      </FormValuesField>
                    </Flex>
                  </Flex>
                  <Flex gap={8}>
                    <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                  </Flex>
                </Flex>
              </form>
            )}
          />
        </Flex>
        <Flex css={{ flexBasis: '50%' }} />
      </Flex>
    </SectionLoader>
  )
}

const xAxis = {
  title: {
    text: 'Months'
  },
  categories: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
}

const Invoices = () => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()

  const queryVariables = {
    limit: pageSize,
    page
  }

  const {
    data: { workspaceOrdersList = [], workspaceOrdersAggregate = {} } = {},
    loading,
    error
  } = useWorkspaceOrdersListQuery({
    variables: queryVariables
  })

  const yAxis = {
    min: 0,
    title: {
      text: 'Billed Amount',
      suffix: workspaceOrdersList[0]?.currencyCode || 'USD'
    }
  }

  const columns: Column[] = [
    { dataKey: 'craetedAt', title: 'Invoice', style: { flexGrow: 1 }, renderer: InvoiceNameRenderer },
    { dataKey: 'total', title: 'Total', style: { width: 200, justifyContent: 'flex-end' }, renderer: CurrencyRenderer },
    { dataKey: 'status', title: 'Status', style: { width: 200, justifyContent: 'flex-end' }, renderer: StatusRenderer }
  ]

  // const actions = [
  //   {
  //     icon: 'payment-methods',
  //     title: 'Pay',
  //     onClick: () => { }
  //   },
  //   {
  //     icon: 'download',
  //     title: 'Download',
  //     onClick: () => { }
  //   }
  // ]

  return (
    <>
      <Card css={{ padding: 35 }}>
        <BarChart
          type="column"
          xAxis={xAxis}
          yAxis={yAxis}
          series={[
            {
              name: 'Billed Amount',
              type: 'column',
              data: Array.apply(0, Array(12)).map((_, index) => {
                const month = workspaceOrdersList.find(
                  (order) => new Date(order.createdAt).getMonth() - 1 === index
                )
                return month ? Number.parseFloat(month.total) : 0
              })
            }
          ] as SeriesColumnOptions[]}
        />
      </Card>
      <DataTable
        actions={[]} // actions
        columns={columns}
        data={workspaceOrdersList}
        error={error}
        loading={loading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        selectionMode="none"
        totalRows={workspaceOrdersAggregate?.count || 0}
      />
    </>
  )
}

const LIST_PAYMENT_METHODS_VARIABLES = {
  limit: 100,
  order: [ { createdAt: 'asc' } ]
}

const CardsContainer = styled(Box, {
  position: 'relative'
})

const CardsList = styled(Flex, {
  ...mixins.transition('fluid'),

  alignItems: 'center',
  flexWrap: 'nowrap',
  margin: '0 auto',
  width: '100%',
  // overFlowY: 'visible',
  overflowX: 'auto',
  // overflowY: 'visible',
  padding: '16px 8px 32px 16px',

  '& > *:last-child': {
    marginRight: 100
  }
})

const CardsContainerScrollOverlay = styled('div', {
  zIndex: 999,
  display: 'block',
  width: 100,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  background: `linear-gradient(90deg, ${rgba(colorVars.dark100rgb, 0.1)} 0%, ${colorVars.light400} 80%)`
})

const CardActions = styled(Flex, {
  alignItems: 'center',
  color: 'dark100',
  display: 'flex',
  position: 'absolute',
  top: 20,
  right: 20
})

const PaymentMethods = ({ data, loading, error }: {
  data: PaymentMethod[],
  loading: boolean,
  error?: ApolloError
}) => {
  const { openView } = useViewDispatch()

  const [
    setPaymentMethodAsDefault
  ] = useSetWorkspacePaymentMethodAsDefaultMutation({
    refetchQueries: [
      WorkspacePaymentMethodsListDocument
    ]
  })

  const [
    destroyPaymentMethod
  ] = useDestroyWorkspacePaymentMethodMutation({
    // TODO: replace this with optimistic update
    refetchQueries: [
      WorkspacePaymentMethodsListDocument
    ]
  })

  const handleSetPaymentMethodAsDefault = useSubmitHandler(setPaymentMethodAsDefault, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'setWorkspacePaymentMethodAsDefault',
      typename: 'PaymentMethod',
      // TODO: fix this
      override: (values: SetWorkspacePaymentMethodAsDefaultInput) => ({
        __typename: 'PaymentMethod',
        ...data.find((paymentMethod) => paymentMethod.id === values.id),
        ...values,
        isDefault: true
      })
    },
    update: (cache, result) => {
      cache.writeQuery<WorkspacePaymentMethodsListQuery>({
        query: WorkspacePaymentMethodsListDocument,
        variables: LIST_PAYMENT_METHODS_VARIABLES,
        data: {
          workspacePaymentMethodsAggregate: {
            count: data.length
          },
          workspacePaymentMethodsList: data.map((item) => ({
            ...item,
            isDefault: result.data?.setWorkspacePaymentMethodAsDefault.id === item.id
          }))
        }
      })
    },
    successAlert: { message: 'Payment method set as default' },
    failureAlert: { message: 'Failed to set payment method as default' }
  })

  const handleDestroyPaymentMethod = useSubmitHandler(destroyPaymentMethod, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyWorkspacePaymentMethod',
      typename: 'PaymentMethod'
    },
    successAlert: { message: 'Payment method deleted' },
    failureAlert: { message: 'Failed to delete payment method' }
  })

  const onAddPaymentMethod = () => openView({
    title: 'Add Payment Method',
    component: AddPaymentMethodView,
    params: {},
    style: 'DIALOG'
  })

  return (
    <Flex direction="column" gap={16}>
      <SectionLoader data={data} loading={loading} error={error}>
        <CardsContainer>
          <CardsContainerScrollOverlay />
          <CardsList gap={16}>
            {data.map(({ id, card, isDefault }, index) => card && (
              <CreditCard
                key={id}
                id={id}
                index={index}
                brand={card.brand as Brands}
                last4={card.last4}
                expiryMonth={card.expiryMonth}
                expiryYear={card.expiryYear}
              >
                <CardActions>
                  {isDefault ? (
                    <Chip label="Default" variant="primary_inverse" icon="star-filled" />
                  ) : (
                    <>
                      <IconButton
                        name="star-empty"
                        variant="light"
                        description="Set As Default"
                        onClick={() => handleSetPaymentMethodAsDefault({ id })}
                      />
                      <Divider orientation="vertical" spacing={8} />
                      <IconButton
                        name="trash"
                        variant="light"
                        description="Delete"
                        onClick={() => handleDestroyPaymentMethod({ id })}
                      />
                    </>
                  )}
                </CardActions>
              </CreditCard>
            ))}
          </CardsList>
        </CardsContainer>
      </SectionLoader>
      <Divider orientation="horizontal" spacing={16} variant="ruler" />
      <Flex justifyContent="flex-end">
        <Button type="submit" label="Add Payment Method" onClick={onAddPaymentMethod} />
      </Flex>
    </Flex>
  )
}

function BillingPage() {
  const {
    data: { fetchCurrentWorkspaceOrder } = {},
    ...fetchCurrentWorkspaceOrderRest
  } = useFetchCurrentWorkspaceOrderQuery()

  const {
    data: fetchWorkspaceBillingAccountData,
    ...fetchWorkspaceBillingAccountResult
  } = useFetchWorkspaceBillingAccountQuery()

  const {
    data: { workspacePaymentMethodsList = [] } = {},
    ...workspacePaymentMethodsListRest
  } = useWorkspacePaymentMethodsListQuery({
    variables: LIST_PAYMENT_METHODS_VARIABLES
  })

  const {
    data: { workspaceOrdersList } = {}
  } = useWorkspaceOrdersListQuery({
    variables: {
      limit: 100
    }
  })

  const total = `${fetchCurrentWorkspaceOrder?.currencyCode} ${Number.parseFloat(fetchCurrentWorkspaceOrder?.total).toFixed(2)}`
  const nextMonth = dayjs(new Date()).add(1, 'month')
  const nextMonthFirstDay = nextMonth.startOf('month')

  const defaultPaymentMethod = workspacePaymentMethodsList.find(
    (paymentMethod) => paymentMethod.isDefault
  )

  const pendingOrders = workspaceOrdersList?.filter(
    (order) => !NOT_PENDING_ORDER_TYPES.includes(order.status)
  )

  const usageSummary = fetchCurrentWorkspaceOrder?.total > 0
    ? `You will be billed ${total} on  ${nextMonthFirstDay.format('MMM D YYYY')}`
    : 'No usage'
  const paymentMethodsSummary = defaultPaymentMethod
    ? `${defaultPaymentMethod.card?.brand.toUpperCase()} credit card ending in ${defaultPaymentMethod.card?.last4}`
    : 'No payment methods'
  const invoiceSettingsSummary = fetchWorkspaceBillingAccountData
    ? fetchWorkspaceBillingAccountData.fetchWorkspaceBillingAccount.name
    : 'Not set'
  const invoicesSummary = (pendingOrders?.length || 0) > 0
    ? `${pendingOrders?.length} pending invoices`
    : 'All paid'

  return (
    <>
      <TitleBlock heading="Billing" />
      <DrawerBlock icon="current-plan" title="Current Usage" summary={usageSummary}>
        {() => (
          <UsageSummary
            data={fetchCurrentWorkspaceOrder as CustomOrder}
            {...fetchCurrentWorkspaceOrderRest}
          />
        )}
      </DrawerBlock>

      <DrawerBlock icon="payment-methods" title="Payment Methods" summary={paymentMethodsSummary}>
        {() => (
          <PaymentMethods
            data={workspacePaymentMethodsList as PaymentMethod[]}
            {...workspacePaymentMethodsListRest}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="invoice-settings"
        summary={invoiceSettingsSummary}
        title="Invoice Settings"
      >
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <InvoiceSettings
            data={fetchWorkspaceBillingAccountData?.fetchWorkspaceBillingAccount}
            {...fetchWorkspaceBillingAccountResult}
            onClose={closeDrawer}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="invoices"
        title="Invoices"
        summary={invoicesSummary}
      >
        {() => (
          <Invoices />
        )}
      </DrawerBlock>
    </>
  )
}

export default BillingPage

export { LIST_PAYMENT_METHODS_VARIABLES }
